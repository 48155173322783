<template>
  <div class="tender-notice-detail container">
    <!-- 主体 -->
    <div class="main">
      <!-- 标题 -->
      <div class="title">
        <h1>{{noticeInfo.packageName}}</h1>
        <div class="release-date">
          <span>发布时间：</span>
          <span>{{noticeInfo.createTime|filterDate}}</span>
        </div>
      </div>

      <!-- 招标信息 -->
      <div class="tender-info">
        <div class="item">
          <label>招标名称</label>
          <span>{{noticeInfo.projectName}}</span>
        </div>
        <div class="item">
          <label>包件编号</label>
          <span>{{noticeInfo.packageNo}}</span>
        </div>
        <div class="item">
          <label>是否为电子标</label>
          <span>{{noticeInfo.electronic | filterElectronicType}}</span>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <div
          class="content-text"
          v-html="noticeInfo.announceContent"
        ></div>
        <FileList :fileList="noticeInfo.noticeFilePath"></FileList>

        <!-- <div class="content-file">
          <p>
            <i class="iconfont icon-fujian"></i>
            <span>公告附件</span>
          </p>
          <template v-if="noticeInfo.attachFilePath.length > 0">
            <a :href="baseURL+v" target="_blank" 
              v-for="(v,i) in noticeInfo.attachFilePath" :key="i" class="file_list">
              <span>{{ v.substring( v.lastIndexOf('/')+1 ) }}</span>
              <span>
                <img src="@/assets/images/download/ic_xz@2x.png" />
                <el-button type="text">下载</el-button>
              </span>
            </a>
          </template>
          <a v-else class="file_list" style="justify-content: center;">无附件</a>
        </div> -->
      </div>
      
    </div>
  </div>
</template>

<script>
import tradeInfoApi from "@/api/tradeInfo";
import { mapState } from "vuex";
import FileList from '@/components/FileList/index.vue'

export default {
  components: {
    FileList
  },
  async created() {
    // 获取地址栏传过来的参数
    this.query = this.$route.query;
    // 根据参数获取详情
    let { data } = await tradeInfoApi.findTradeDetail(this.query);
    this.noticeInfo = data
    this.noticeInfo.attachFilePath = JSON.parse(this.noticeInfo.attachFilePath);
    this.bidSectionInfo = [...data.bidSectionInfos];
  },
  data() {
    return {
      query: { id: "", type: "", isGov: "" },
      type: "", //判断是 招标公告 还是 变更公告
      noticeInfo: {
        attachFilePath: []
      },
      bidSectionInfo: []
    };
  },
  computed: {
    ...mapState(["baseURL", "bidderURL"])
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterDate (val) {
      return moment(val).format("YYYY-MM-DD");
    }
  }
};
</script>

<style lang="less" scoped>
.tender-notice-detail {
  // 主体
  .main {
    // 标题
    .title {
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px dashed rgba(236, 236, 241, 1);
      h1 {
        color: #20201E;
        font-size: 22px;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .release-date {
        color: #999999;
        font-size: 14px;
      }
    }

    // 招标信息
    .tender-info {
      margin-bottom: 20px;
      margin-top: 24px;
      border: 1px solid rgba(236, 236, 241, 1);
      border-right: 0;
      border-bottom: 0;
      display: grid;
      // grid-template-columns: 206px 370px 206px 370px;
      grid-template-columns: repeat(2 50%);
      .item {
        font-size: 16px;
        color: rgba(73, 74, 76, 1);
        display: inline-block;
        line-height: 50px;
        border-bottom: 1px solid rgba(236, 236, 241, 1);
        border-right: 1px solid rgba(236, 236, 241, 1);
        &:first-child {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        label {
          width: 206px;
          display: inline-block;
          border-right: 1px dashed rgba(236, 236, 241, 1);;
          color: #494A4C;
          font-weight: bold;
        }
        span {
          color: #20201E;
          a {
            color: #6C8BC6;
          }
        }
        label, span {
          padding-left: 24px;
        }
      }
    }

    // 内容
    .content {
      margin-bottom: 20px;
      .content-title {
        padding-left: 10px;
        border-left: 5px solid #3660d9;
        margin-bottom: 20px;
      }
      .content-text {
        color: rgba(73, 74, 76, 1);
        width: 100%;
        overflow-x: auto;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 28px;
        /deep/section, /deep/div, /deep/p {
          line-height: 28px !important;
        }
        /deep/div {
          margin-bottom: 5px;
        }
        font-family: PingFang SC-Regular, PingFang SC;
      }
      .content-file {
        line-height: 20px;
        p {
          line-height: 48px;
          background: #ECECF1;
          font-size: 18px;
          font-weight: bold;
          color: rgba(32, 32, 30, 1);
          padding-left: 15px;
          margin-top: 35px;
        }
        .file_list {
          border-bottom: 1px dashed rgba(236, 236, 241, 1);
          line-height: 68px;
          display: flex;
          justify-content: space-between;
          color: rgba(32, 32, 30, 1);
          img {
            width: 24px;
            margin-right: 4px;
          }
        }
        a {
          color: #3660d9;
        }
      }
    }

    // 表格
    .el-table {
      margin: 33px 0;
      /deep/th {
        background-color: #F4F4F4;
        height: 48px;
        color: #333;
        text-align: left;
        padding-left: 20px;
        font-weight: bold;
      }
      .bid {
        color: rgba(243, 110, 49, 1);
        img {
          width: 16px;
        }
      }
      /deep/tr {
        height: 40px;
        td {
          padding-left: 20px;
        }
        &:nth-child(odd) {
          // background: #ECECF1;
        }
      }
    }
  }
}
</style>